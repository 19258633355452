import React from "react"

export default function Banner({ classNameValue, titleContent }) {
  // state

  // Comportments

  //Display

  return <div className={`banner ${classNameValue}`}>{titleContent}</div>;
}
